import React, { useContext, useEffect, useState } from "react";
import MainContext from "../../MainContext";
import { Link } from "react-router-dom";
export default function CompanyPage() {
  const ctx = useContext(MainContext);
  useEffect(() => {
    ctx.getDataCompanyList();
  }, []);
  let id = 1;
  if (ctx.state.companylist == null) return <div></div>;
  return (
    <div>
      <h3>
        {ctx.state.language == "en"
          ? "PROPERTY VALUATION CENTER LLC"
          : "ХӨРӨНГИЙН ҮНЭЛГЭЭНИЙ ТӨВ ХХК"}
      </h3>
      <div
        style={{
          borderTop: "3px solid #003366",
          color: "#b2bfd2",
          marginBottom: "0px",
          textAlign: "right",
          fontSize: "12px",
        }}
      >
        {ctx.state.language == "en"
          ? "PROPERTY VALUATION CENTER LLC"
          : "ХӨРӨНГИЙН ҮНЭЛГЭЭНИЙ ТӨВ ХХК"}
      </div>
      {ctx.state.companylist.map((el) => (
        <Link to={`${el.id}`}>
          <div
            style={{
              borderBottom: "1px dotted gray",

              margin: "5px",
              padding: "5px",
            }}
          >
            <table>
              <tr>
                <td style={{ width: "50px" }}>
                  <img
                    src={el.imgdata}
                    height="40px"
                    style={{ borderRadius: "10px" }}
                  />
                </td>
                <td>
                  <h4>{el.title}</h4>
                </td>
              </tr>
            </table>
          </div>
        </Link>
      ))}
    </div>
  );
}
