import React, { useContext, useEffect, useState } from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import MainContext from "../../MainContext";
export default function NoMatch() {
  const ctx = useContext(MainContext);

  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle={
          ctx.state.language == "mn"
            ? "Уучлаарай таны хайсан хуудас олдсонгүй"
            : "Sorry, The page you visited does not exists, "
        }
        extra={
          <Link to="/">
            <Button type="primary">
              {ctx.state.language == "mn"
                ? "Нүүр хуудасруу буцах"
                : "Back Home"}
            </Button>
          </Link>
        }
      />
    </div>
  );
}
