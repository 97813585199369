import React, { useContext } from "react";
import { Image } from "antd";
import parse from "html-react-parser";
import MainContext from "../MainContext";
const ContentViewer = (props) => {
  const ctx = useContext(MainContext);
  return (
    <div>
      <div
        style={{
          color: "#222",
          fontFamily: "oscl,tahoma,Arial,sans-serif",
          fontSize: "20px",
          fontWeight: 400,
          margin: "10px 0",
          textAlign: "left",
        }}
      >
        {props.title}
      </div>
      <div
        style={{
          borderTop: "3px solid #003366",
          color: "#b2bfd2",
          marginBottom: "0px",
          textAlign: "right",
          fontSize: "12px",
        }}
      >
        {ctx.state.language == "en"
          ? "PROPERTY VALUATION CENTER LLC"
          : "ХӨРӨНГИЙН ҮНЭЛГЭЭНИЙ ТӨВ ХХК"}
      </div>
      <div>
        <img src={props.imgdata} width="100%" />
      </div>
      {props.content && (
        <div style={{ textAlign: "justify" }}>{parse("" + props.content)}</div>
      )}
    </div>
  );
};
export default ContentViewer;
