import React, { useContext, useEffect, useState } from "react";
import MainContext from "../../MainContext";
import MySideBar from "../component/MySideBar";
import css from "../content.module.css";
import ContentViewer from "../ContentViewer";
import MetaTags from "react-meta-tags";
export default function HomePage() {
  const ctx = useContext(MainContext);
  useEffect(() => {
    ctx.getDataHome();
  }, []);

  return (
    <div>
      <MetaTags>
        <meta name="description" content={ctx.state.datahome?.title} />
        <meta property="og:title" content={ctx.state.datahome?.title} />
        <meta property="og:image" content={ctx.state.datahome?.imgdata} />
      </MetaTags>
      <ContentViewer
        title={ctx.state.datahome?.title}
        imgdata={ctx.state.datahome?.imgdata}
        content={ctx.state.datahome?.content}
      />
    </div>
  );
}
