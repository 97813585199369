import React, { useContext, useEffect, useState } from "react";
import ContentViewer from "./ContentViewer";
import axios from "../myaxios";
import MainContext from "../MainContext";
const ContentPage = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const ctx = useContext(MainContext);

  useEffect(() => {
    setLoading(true);
    axios
      .post("/?requestcode=1001", {
        language: ctx.state.language,
        position: props.position,
      })
      .then((response) => {
        setLoading(true);
        setData(response.data.result);
      })
      .catch((ex) => {
        setLoading(false);
        console.log(ex);
      });
  }, [props.position]);

  return (
    <div>
      <ContentViewer
        title={data?.title}
        imgdata={data?.imgdata}
        content={data?.content}
      />
    </div>
  );
};

export default ContentPage;
