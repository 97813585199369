import React, { useEffect, useState } from "react";
import axios from "../../myaxios";
import { useParams } from "react-router-dom";
import ContentViewer from "../ContentViewer";
import { Spin } from "antd";
import NoMatch from "./NoMatch";
export default function CompanyDetails() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  let params = useParams();
  useEffect(() => {
    setLoading(true);
    axios
      .post("/?requestcode=1004", {
        id: params.id,
      })
      .then((response) => {
        setLoading(false);
        setData(response.data.result);
      })
      .catch((ex) => {
        setLoading(false);
      });
  }, [params.id]);

  if (loading) return <Spin />;
  if (data != null)
    return (
      <ContentViewer
        title={data?.title}
        imgdata={data?.imgdata}
        content={data?.content}
      />
    );
  return (
    <div>
      <NoMatch />
    </div>
  );
}
