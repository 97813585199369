import React, { useContext, useState } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { Menu } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import MainContext from "../MainContext";

const { SubMenu } = Menu;

export default function MyMenu() {
  const ctx = useContext(MainContext);
  const [current, setCurrent] = useState("mail");
  const handleClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };
  return (
    <div style={{ textAlign: "center", backgroundColor: "blue" }}>
      <Menu
        overflowedIndicator="..."
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
        style={{
          textAlign: "center",
          position: "relative",
          display: "flex",
          fontSize: "16px",
          color: "#0079a9",
          justifyContent: "center",
        }}
      >
        <Menu.Item key="product" icon={<AppstoreOutlined />}>
          <Link to="/product">
            {ctx.state.language == "mn" ? "Бүтээгдэхүүн" : "Products"}
          </Link>
        </Menu.Item>
        <Menu.Item key="price" icon={<AppstoreOutlined />}>
          <Link to="/price">
            {ctx.state.language == "mn" ? "Үнэ тариф" : "Price"}
          </Link>
        </Menu.Item>
        <Menu.Item key="news" icon={<AppstoreOutlined />}>
          <Link to="/news">
            {ctx.state.language == "mn" ? "Мэдээлэл" : "Information"}
          </Link>
        </Menu.Item>
        <Menu.Item key="company" icon={<AppstoreOutlined />}>
          <Link to="/company">
            {ctx.state.language == "mn" ? "Компаний тухай" : "Company"}
          </Link>
        </Menu.Item>
        <Menu.Item key="contact" icon={<AppstoreOutlined />}>
          <Link to="/contact">
            {ctx.state.language == "mn" ? "Холбоо барих" : "Contacts"}
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
}
