import React, { useContext, useEffect, useState } from "react";
import MainContext from "../../MainContext";
import MySideBar from "../component/MySideBar";
import css from "../content.module.css";
import ContentViewer from "../ContentViewer";
export default function ContactPage() {
  const ctx = useContext(MainContext);
  useEffect(() => {
    ctx.getDataContact();
  }, []);

  return (
    <ContentViewer
      title={ctx.state.datacontact?.title}
      imgdata={ctx.state.datacontact?.imgdata}
      content={ctx.state.datacontact?.content}
    />
  );
}
