import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? `https://unelgeeniituv.mn/admin/api/index.php`
      : "http://localhost/unelgeeapi",
});
instance.defaults.withCredentials = true;
export default instance;
