import React, { createContext, useContext, useEffect } from "react";
import MainContext from "../MainContext";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
const SideContent = (props) => {
  const ctx = useContext(MainContext);

  return (
    <div key={props.id} style={{ textAlign: "left", fontSize: "14px" }}>
      <Link to={`news/${props.id}`}>
        <table key={props.id}>
          <tr key={props.id}>
            <td>
              <img src={logo} height="20px" width="20px" />
            </td>
            <td style={{ paddingLeft: "5px" }}>
              <span style={{ padding: "3px" }}>{props.name}</span>
            </td>
          </tr>
        </table>
      </Link>
    </div>
  );
};

export default SideContent;
