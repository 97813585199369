import React, { createContext, useContext } from "react";

import { Row, Col } from "antd";

import MySizer from "./MySizer";

import css from "./footer.module.css";
import MainContext from "../MainContext";
import { Link } from "react-router-dom";
export default function Footer() {
  const ctx = useContext(MainContext);
  return (
    <div className={css.lfooter}>
      <MySizer>
        <hr />
        <Row>
          <Col xs={24} xl={6} style={{ textAlign: "left", padding: "10px" }}>
            <div style={{ fontSize: "12px" }}>
              <Link to="/terms">
                <span style={{ color: "#003366" }}>
                  {ctx.state.language == "mn" ? "Үйлчилгээний зардал" : "Terms"}
                </span>
              </Link>
              <div>
                <Link to="/privacy">
                  <span style={{ color: "#003366" }}>
                    {ctx.state.language == "mn"
                      ? "Нууцлалын бодлого"
                      : "Privacy"}
                  </span>
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={24} xl={12} style={{ textAlign: "left", padding: "10px" }}>
            <div style={{ fontSize: "12px" }}>
              <div style={{ fontWeight: "bold" }}>
                {ctx.state.language == "mn" ? "Манай хаяг" : "Address"}
              </div>
              <div>{ctx.state.contact?.address}</div>
              <div style={{ fontWeight: "bold" }}>
                {ctx.state.language == "mn"
                  ? "Бид таныг хүлээж байна"
                  : "Time table"}
              </div>
              <div>{ctx.state.contact?.activehour}</div>
            </div>
          </Col>
          <Col xs={24} xl={6} style={{ textAlign: "left", padding: "10px" }}>
            <div style={{ fontSize: "12px" }}>
              {ctx.state.language == "mn" ? "Утас: (976)" : "Phone: (976)"}
              {ctx.state.contact?.phone}, <br />
              {ctx.state.language == "mn" ? "Факс: (976)" : "Fax: (976)"}
              {ctx.state.contact?.fax}, <br />
              {ctx.state.language == "mn" ? "Э-шуудан: " : "Email: "}
              {ctx.state.contact?.email}, <br />
              {ctx.state.language == "mn" ? "Вэб хуудас: " : "Website: "}
              {ctx.state.contact?.website}, <br />
            </div>
          </Col>
        </Row>
      </MySizer>
    </div>
  );
}
