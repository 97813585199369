import React, { useContext, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MyMenu from "../components/MyMenu";
import MySizer from "../components/MySizer";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import HomePage from "../components/content/HomePage";
import TestPage from "../components/content/TestPage";
import ContactPage from "../components/content/ContactPage";
import NoMatch from "../components/content/NoMatch";
import PricePage from "../components/content/PricePage";
import ProductPage from "../components/content/ProductPage";

import CompanyPage from "../components/content/CompanyPage";
import NewsPage from "../components/content/NewsPage";
import NewsDetails from "../components/content/NewsDetails";
import { Row, Col, Divider, Spin } from "antd";
import "./mycontainer.css";
import MainContext from "../MainContext";
import SideContent from "../components/SideContent";
import CompanyDetails from "../components/content/CompanyDetails";
import ContentPage from "../components/ContentPage";
const style = { background: "#0092ff", padding: "8px 0" };

export default function Home() {
  const ctx = useContext(MainContext);

  useEffect(() => {
    ctx.getProductList();
  }, []);

  return (
    <div className="wrapper">
      <Header />
      <main>
        <MySizer>
          <div className="sidebar">
            {ctx.state.loadingSidebar && <Spin />}
            {ctx.state.productlist?.map((el) => (
              <a>
                <SideContent id={el.id} name={el.title} icon={el.imgdata} />
              </a>
            ))}
          </div>
          <div className="content">
            <Routes>
              <Route index element={<HomePage />} />
              <Route path="product" element={<ProductPage />} />
              <Route path="price" element={<PricePage />} />
              <Route path="news" element={<NewsPage />} />
              <Route path="news/:newsId" element={<NewsDetails />} />
              <Route path="company" element={<CompanyPage />} />
              <Route path="company/:id" element={<CompanyDetails />} />
              <Route path="contact" element={<ContactPage />} />
              <Route path="terms" element={<ContentPage position="terms" />} />
              <Route
                path="privacy"
                element={<ContentPage position="privacy" />}
              />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </div>
        </MySizer>
      </main>

      <Footer />
    </div>
  );
}
