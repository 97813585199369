import React from "react";

const MySizer = (props) => {
  return (
    <div
      style={{
        maxWidth: "1024px",
        minWidth: "240px",
        textAlign: "center",
        margin: "0 auto",
      }}
    >
      {props.children}
    </div>
  );
};
export default MySizer;
