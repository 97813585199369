import React, { createContext, useEffect, useState } from "react";
import axios from "./myaxios";
import { message, Spin } from "antd";

const MainContext = React.createContext();
const initialState = {
  contact: null,
  language: localStorage.getItem("language") == "en" ? "en" : "mn",
  productlist: null,
  loadingSidebar: false,
  datahome: null,
  datacontact: null,
  loadingDataPrice: false,
  dataPrice: null,
  loadingDataProduct: false,
  dataProduct: null,
  companylist: null,
  loadingCompanyList: false,
};

export const MainStore = (props) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    axios
      .post("/?requestcode=1003", { language: state.language })
      .then((response) => {
        setState((state) => ({ ...state, contact: response.data.result }));
      })
      .catch((ex) => console.log(ex));
  }, []);

  const setLanguage = (lang) => {
    setState((state) => ({
      ...state,
      language: lang,
    }));
    localStorage.setItem("language", lang);
    window.location.reload(true);
  };

  const getProductList = () => {
    setState((state) => ({
      ...state,
      loadingSidebar: true,
    }));

    axios
      .post("/?requestcode=1002", {
        language: state.language,
        position: "product",
      })
      .then((response) => {
        setState((state) => ({
          ...state,
          productlist: response.data.result,
          loadingSidebar: false,
        }));
      })
      .catch((ex) => {
        setState((state) => ({
          ...state,
          loadingSidebar: false,
        }));
      });
  };

  const getDataHome = () => {
    setState((state) => ({
      ...state,
      loadingDatahome: true,
    }));

    axios
      .post("/?requestcode=1001", {
        language: state.language,
        position: "home",
      })
      .then((response) => {
        setState((state) => ({
          ...state,
          datahome: response.data.result,
          loadingDatahome: false,
        }));
      })
      .catch((ex) => {
        setState((state) => ({
          ...state,
          loadingDatahome: false,
        }));
      });
  };

  const getDataContact = () => {
    setState((state) => ({
      ...state,
      loadingDatacontact: true,
    }));

    axios
      .post("/?requestcode=1001", {
        language: state.language,
        position: "contact",
      })
      .then((response) => {
        setState((state) => ({
          ...state,
          datacontact: response.data.result,
          loadingDatacontact: false,
        }));
      })
      .catch((ex) => {
        setState((state) => ({
          ...state,
          loadingDatacontact: false,
        }));
      });
  };

  const getDataPrice = () => {
    setState((state) => ({
      ...state,
      loadingDataPrice: true,
    }));

    axios
      .post("/?requestcode=1001", {
        language: state.language,
        position: "price",
      })
      .then((response) => {
        setState((state) => ({
          ...state,
          dataPrice: response.data.result,
          loadingDataPrice: false,
        }));
      })
      .catch((ex) => {
        setState((state) => ({
          ...state,
          loadingDataPrice: false,
        }));
      });
  };

  const getDataProduct = () => {
    setState((state) => ({
      ...state,
      loadingDataProduct: true,
    }));

    axios
      .post("/?requestcode=1001", {
        language: state.language,
        position: "productpage",
      })
      .then((response) => {
        setState((state) => ({
          ...state,
          dataProduct: response.data.result,
          loadingDataProduct: false,
        }));
      })
      .catch((ex) => {
        setState((state) => ({
          ...state,
          loadingDataProduct: false,
        }));
      });
  };
  const getDataNewsList = () => {
    setState((state) => ({
      ...state,
      loadingNewsList: true,
    }));

    axios
      .post("/?requestcode=1002", {
        language: state.language,
        position: "news",
      })
      .then((response) => {
        setState((state) => ({
          ...state,
          newslist: response.data.result,
          loadingNewsList: false,
        }));
      })
      .catch((ex) => {
        setState((state) => ({
          ...state,
          loadingNewsList: false,
        }));
      });
  };
  const getDataCompanyList = () => {
    setState((state) => ({
      ...state,
      loadingCompanyList: true,
    }));

    axios
      .post("/?requestcode=1002", {
        language: state.language,
        position: "company",
      })
      .then((response) => {
        setState((state) => ({
          ...state,
          companylist: response.data.result,
          loadingCompanyList: false,
        }));
      })
      .catch((ex) => {
        setState((state) => ({
          ...state,
          loadingCompanyList: false,
        }));
      });
  };

  return (
    <MainContext.Provider
      value={{
        state,
        setLanguage,
        getProductList,
        getDataHome,
        getDataContact,
        getDataPrice,
        getDataProduct,
        getDataNewsList,
        getDataCompanyList,
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export default MainContext;
