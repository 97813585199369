import logo from "./logo.svg";
import "./App.css";
import Home from "./page/Home";
import { MainStore } from "./MainContext";

function App() {
  return (
    <div className="App">
      <div style={{ textAlign: "center" }}>
        <MainStore>
          <Home />
        </MainStore>
      </div>
    </div>
  );
}

export default App;
