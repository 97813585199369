import React, { createContext, useContext } from "react";
import css from "./header.module.css";
import { Row, Col } from "antd";
import mainLogo from "../assets/logoname.png";
import MySizer from "./MySizer";
import MyMenu from "./MyMenu";
import mn from "../assets/mn.svg";
import en from "../assets/en.svg";
import MainContext from "../MainContext";
import { Link } from "react-router-dom";
export default function Header() {
  const ctx = useContext(MainContext);
  return (
    <div className={css.lheader}>
      <MySizer>
        <Row>
          <Col
            xs={24}
            xl={12}
            style={{ textAlign: "left", paddingLeft: "10px" }}
          >
            <Link to="/">
              <img src={mainLogo} height="auto" width="256px" />
            </Link>
          </Col>
          <Col xs={24} xl={12} style={{ textAlign: "right" }}>
            <div style={{ paddingRight: "14px" }}>
              {ctx.state.language == "mn" && (
                <div
                  style={{ fontSize: "13px" }}
                  onClick={() => ctx.setLanguage("en")}
                >
                  <img src={en} height="25px" /> English
                </div>
              )}
              {ctx.state.language == "en" && (
                <div
                  onClick={() => ctx.setLanguage("mn")}
                  style={{ fontSize: "13px" }}
                >
                  <img src={mn} height="25px" /> Монгол хэл
                </div>
              )}
              <div style={{ fontSize: "14px", fontFamily: "Arial" }}>
                {ctx.state.contact != null && ctx.state.contact.email}
              </div>
              <a
                href="https://www.facebook.com/100054448571263"
                style={{
                  fontSize: "14px",
                  fontFamily: "Arial",
                  color: "#003366",
                }}
              >
                <img
                  src="https://findicons.com/files/icons/2287/32_pixel_social_media_icons/32/facebook.png"
                  height="20px"
                />
                {ctx.state.language == "en" ? (
                  <span style={{ marginLeft: "5px" }}>Facebook</span>
                ) : (
                  <span style={{ marginLeft: "5px" }}>Фэйсбүүк</span>
                )}
              </a>
              <a
                href={`tel:976${ctx.state.contact?.phone}`}
                style={{ color: "black" }}
              >
                <div style={{ fontSize: "20px" }}>
                  + <span style={{ color: "red" }}>(976)</span>
                  {ctx.state.contact?.phone}
                </div>
              </a>
            </div>
          </Col>
        </Row>
        <MyMenu />
      </MySizer>
    </div>
  );
}
